import React from 'react';
import './Home.css';
import { Link } from 'react-scroll';
import SvgHomepagebackground from '../../components/Svgs/Homepagebackground';

const Home: React.FC = () => {
  return (
     <div id="home" className="home-container">
      <SvgHomepagebackground className="homepage-background-svg" />``
      <div className="overlay-block-left">
        <div className="overlay-block-left-inner">
          <div className="text-container">
            <h1 className="drive-text fly-in">Drive <span className="legal-smart-text fly-in">Legal</span></h1>
            <h1 className="drive-text fly-in">Drive <span className="legal-smart-text fly-in smart">Smart</span></h1>
            <p className="sub-text">Effortless Vehicle Registration for Dealerships, Individuals and Fleets</p>
          </div>
          <div className="buttons">
            <Link to="contact-us" smooth={true} duration={500} offset={-120}>
              <a className="button">Get in Touch</a>
            </Link>
            <Link to="services" smooth={true} duration={500} offset={-120}>
              <a className="services-button">Our Services</a>
            </Link>          
          </div>
        </div>
      </div>
      <div className="overlay-block-right"></div> */
   </div>
  );
};

export default Home;