import React from 'react';
import './ContactUsDialog.css';
import Logo from '../../components/Svgs/Logo';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  message: string;
}

const ContactUsDialog: React.FC<ModalProps> = ({ show, onClose, message }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <Logo className="modal-logo" />
        <p className="modal-message">{message}</p>
        <button className="modal-close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ContactUsDialog;
