import React from 'react';
import Home from '../Home/Home';
import AboutUs from '../AboutUs/AboutUs';
import Services from '../Services/Services';
import ContactUs from '../ContactUs/ContactUs';
import NavigationBar from '../Navbar/Navbar';
import './Main.css';

const Main: React.FC = () => {

  return (
    
    <div>
      <section id="navbar" className="navbar-section">
        <NavigationBar />
      </section>

      <section id="home" className="section home-section">
        <Home />
      </section>

      <section id="about-us" className="section about-us-section">
        <AboutUs />
      </section>

      <section id="services" className="section services-section">
        <Services />
      </section>

      <section id="contact-us" className="section contact-us-section">
        <ContactUs />
      </section>
    </div>
  );
};

export default Main;