import React, { useState } from 'react';
import './ContactUs.css';
import emailjs from 'emailjs-com';
import ContactUsSVG from '../../components/Svgs/ContactUsSVG';
import Testimonial from '../Testimonial/Testimonial';
import Logo from '../../components/Svgs/Logo';
import ContactUsDialog from '../ContactUsDialog/ContactUsDialog';

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    contactNumber: '',
    email: '',
    message: ''
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = () => {
    return formData.fullName && isValidEmail(formData.email) && formData.message;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const formattedMessage = `
      Name: ${formData.fullName}
      Contact Number: ${formData.contactNumber}
      Email: ${formData.email}
      
      Message:
      ${formData.message}
    `;

    emailjs.send(
      'service_vza8f3n',
      'template_6csf6f6',
      {
        fullName: formData.fullName,
        contactNumber: formData.contactNumber,
        email: formData.email,
        message: formattedMessage
      },
      'IBF168lA-32_dDOmg'
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setModalMessage('Your message has been sent!');
      setIsModalOpen(true);
      setFormData({
        fullName: '',
        contactNumber: '',
        email: '',
        message: ''
      });
    }).catch((error) => {
      console.error('FAILED...', error);
      setModalMessage('There was an error sending your message. Please try again.');
      setIsModalOpen(true);
    });
  };

  return (
    <div className="contact-us-container">
      {/* <div className="testimonial-section">
        <Testimonial />
      </div> */}

      <div className="content-wrapper">
        <div className="contact-us-header">
          <h1>Ready for speedy vehicle registration?</h1>
          <p>Submit your request and let us handle the rest.</p>
        </div>

        <div className="contact-form-wrapper">
          <form className="contact-form" onSubmit={handleSubmit}>
            <label>Full Name:</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            <label>Contact Number:</label>
            <input
              type="text"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
            />
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={4}
              required
            />
            <button type="submit" disabled={!isFormValid()}>Send</button>
          </form>
        </div>

        <ContactUsSVG className="contact-us-background" />
      </div>
      
      <footer>
        <div className="footer-content">
          <div className="footer-text">
            <p>Adrian : 072 774 6326</p>
            <a href="mailto:adrian@areg.co.za">adrian@areg.co.za</a>
          </div>
          <Logo className="footer-logo"> </Logo>
          <div className="footer-text">
            <p>Angelique : 084 318 1789</p>
            <a href="mailto:angelique@areg.co.za">angelique@areg.co.za</a>
          </div>
        </div>
        <div className="footer-line"></div>
        <p className="footer-copyright">Qreg™ All rights reserved</p>
      </footer>
      
      <ContactUsDialog 
        show={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        message={modalMessage}
      />
    </div>
  );
};

export default ContactUs;
