import React from 'react';
import { Link } from 'react-scroll';
import Logo from '../../components/Svgs/Logo';
import './Navbar.css';

const NavigationBar: React.FC = () => {
  console.log('Rendering Navbar');
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo-container">
          <Link to="home" smooth={true} duration={500} offset={-120}>
              <Logo className="logo">
              </Logo>
          </Link>
        </div>
        <div className="nav-links">
          <Link to="about-us" smooth={true} duration={500} offset={-120}>About Us</Link>
          <Link to="services" smooth={true} duration={500} offset={-124}>Services</Link>
          <Link to="contact-us" smooth={true} duration={500} offset={-120}>Contact Us</Link>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
