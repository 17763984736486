import React from 'react';
import './AboutUs.css';
import SvgMultipleCars from '../../components/Svgs/MultipleCars';

const AboutUs: React.FC = () => {

  console.log('Rendering About us');
  return (
    <div id="about-us" className="about-us-container">
      <header className="about-us-header">
        <h1><span className="header-darkgreen">WHERE</span> <span className="header-lightgreen">REGISTRATION</span></h1>
        <h1><span className="header-darkgreen">MEETS</span> <span className="header-lightgreen">ACCELERATION</span></h1>
      </header>
      <div className="about-us-content">
        <div className="left">
          <SvgMultipleCars/>
        </div>
        <div className="right">
          <div className="info-row">
            <div className="info-block">
              <div className="info-content">
                <div className="info-number">01</div>
                  <h2>Where it all started</h2>
                  <p>We have been operating since 2002</p>
              </div>
            </div>
            <div className="info-block">
              <div className="info-content">
                <div className="info-number">02</div>
                  <h2>Time efficiency in check</h2>
                  <p>Our customers can depend on us for accurate and timely registration services.</p>
              </div>
            </div>
          </div>
          <div className="info-row">
            <div className="info-block">
              <div className="info-content">
                <div className="info-number">03</div>
                  <h2>Integrity on top</h2>
                  <p>We conduct our business with honesty and transparency, adhering to all regulations and guidelines.</p>
              </div>
            </div>
            <div className="info-block">
              <div className="info-content">
                <div className="info-number">04</div>
                  <h2>Customer-Centric</h2>
                  <p>We prioritize the needs and satisfaction of our customers, ensuring a positive experience at every interaction.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
